import { FC, useRef, useMemo, useState, useEffect } from "react";
import styles from "./MagneticChipButton.module.scss";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRefStore } from "../../../../store/ref-store";
import LinkTransition from "../../../../transition/components/LinkTransition/LinkTransition";
import Link from "next/link";

interface IMagneticChipButton {
  label?: string;
  onClick?: () => void;
  variant?: string;
  href?: string;
  hidden?: boolean;
  magneticStrength?: number;
  isExternal?: boolean;
}

export const MagneticChipButton: FC<IMagneticChipButton> = ({
  label,
  onClick,
  variant = "accent",
  href,
  hidden,
  magneticStrength = 0.35,
  isExternal = false,
}) => {
  const strength = magneticStrength;
  const spanRef = useRef(null);
  const pointerSuckTl = useMemo(() => gsap.timeline({ paused: true }), []);
  const [hover, setHover] = useState(false);
  const magnetic = useRef(null);
  const { isMobile } = useRefStore();

  useGSAP(() => {
    if (isMobile !== null && !isMobile) {
      pointerSuckTl.to(spanRef.current, {
        duration: 0.5,
        scale: 1.95,
        ease: "power4.inOut",
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile !== null && !isMobile) {
      const xTo = gsap.quickTo(magnetic.current, "x", {
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      });
      const yTo = gsap.quickTo(magnetic.current, "y", {
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      });

      const handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        const { height, width, left, top } =
          magnetic.current.getBoundingClientRect();
        const x = (clientX - (left + width / 2)) * strength;
        const y = (clientY - (top + height / 2)) * strength;
        xTo(x);
        yTo(y);
      };

      const handleMouseLeave = () => {
        xTo(0);
        yTo(0);
      };

      const node = magnetic.current;
      if (node) {
        node.addEventListener("mousemove", handleMouseMove);
        node.addEventListener("mouseleave", handleMouseLeave);
      }

      return () => {
        if (node) {
          node.removeEventListener("mousemove", handleMouseMove);
          node.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }
  }, [strength, isMobile]);

  useGSAP(
    () => {
      if (isMobile !== null && !isMobile) {
        if (hover) {
          pointerSuckTl.play();
        } else {
          pointerSuckTl.reverse();
        }
      }
    },
    { dependencies: [hover, isMobile] }
  );

  function handlePointerMove(
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) {
    if (isMobile !== null && !isMobile) {
      let bounds = e.currentTarget.getBoundingClientRect();
      let mousePos = [e.clientX - bounds.left, e.clientY - bounds.top];

      return gsap.to(spanRef.current, {
        duration: 0,
        x: mousePos[0],
        y: mousePos[1],
      });
    }
    return;
  }

  const handleClick = () => {
    if (onClick) onClick();
  };

  const commonProps = {
    className: `${styles.chipButton} ${styles[variant]} chipBtn ${hidden ? styles.hidden : ""}`,
    onClick: handleClick,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
    onMouseMove: handlePointerMove,
  };

  return (
    <div
      className={`${styles.magnetic} ${variant === "category" ? styles.magneticCategory : ""}`}
      ref={magnetic}
    >
      {href ? (
        isExternal ? (
          <Link href={href} target="_blank" {...commonProps}>
            {label}
            <span
              className={`${styles.magneticCursor} ${styles[variant]}`}
              ref={spanRef}
            />
          </Link>
        ) : (
          <LinkTransition href={href} {...commonProps}>
            {label}
            <span
              className={`${styles.magneticCursor} ${styles[variant]}`}
              ref={spanRef}
            />
          </LinkTransition>
        )
      ) : (
        <button {...commonProps}>
          {label}
          <span
            className={`${styles.magneticCursor} ${styles[variant]}`}
            ref={spanRef}
          />
        </button>
      )}
    </div>
  );
};
