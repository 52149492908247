"use client";
import React, { createContext, useContext, useState, ReactNode } from "react";

export enum AnimationStateKeys {
  IDLE = "idle",
  SIDE = "side",
  JUMP = "jump",
  GREETINGS = "greetings",
  NO = "no",
  YES = "yes",
  BULLY = "bully",
}

const DuckContext = createContext(null);

interface AnimationStateContext {
  id: number;
  state: AnimationStateKeys;
}

export const DuckProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [globalState, setGlobalState] = useState<AnimationStateContext>({
    id: 0,
    state: AnimationStateKeys.IDLE,
  });

  const handleDuckAnimation = (newAnimation: AnimationStateKeys) => {
    const newState = {
      id: globalState.id + 1,
      state: newAnimation,
    };

    setGlobalState(newState);
  };

  return (
    <DuckContext.Provider value={{ globalState, handleDuckAnimation }}>
      {children}
    </DuckContext.Provider>
  );
};

export const useDuck = () => {
  const context = useContext(DuckContext);
  if (!context) {
    throw new Error("useAnimation must be used within an AnimationProvider");
  }
  return context;
};
