import { createWithEqualityFn } from "zustand/traditional";
import { subscribeWithSelector } from "zustand/middleware";

interface useModelStoreType {
  duoDuckModel: any;
  duck: any;
  romanHelmet: any;
  baseballCap: any;
  scrubCap: any;
  detectiveHat: any;
  safetyHelmet: any;
  setDuoDuckModel: () => void;
  setDuck: () => void;
  setRomanHelmet: () => void;
  setBaseballCap: () => void;
  setScrubCap: () => void;
  setDetectiveHat: () => void;
  setSafetyHelmet: () => void;
}

const useModelStore = createWithEqualityFn<useModelStoreType>()(
  subscribeWithSelector((set, get) => ({
    duoDuckModel: null,
    duck: null,
    romanHelmet: null,
    baseballCap: null,
    scrubCap: null,
    detectiveHat: null,
    safetyHelmet: null,
    setDuoDuckModel: () =>
      set((state) => ({ duoDuckModel: state.duoDuckModel })),
    setDuck: () => set((state) => ({ duck: state.duck })),
    setRomanHelmet: () => set((state) => ({ romanHelmet: state.romanHelmet })),
    setBaseballCap: () => set((state) => ({ baseballCap: state.baseballCap })),
    setScrubCap: () => set((state) => ({ scrubCap: state.scrubCap })),
    setDetectiveHat: () =>
      set((state) => ({ detectiveHat: state.detectiveHat })),
    setSafetyHelmet: () =>
      set((state) => ({ safetyHelmet: state.safetyHelmet })),
  }))
);

export default useModelStore;
