import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";
import { useFrame } from "@react-three/fiber";

import { AnimationMixer, MathUtils, sRGBEncoding } from "three";
import useModelStore from "../../../store/model-store";
import { AnimationStateKeys, useDuck } from "@/provider/DuckContext";

import { MeshMatcapMaterial } from "three";
import { useTexture } from "@react-three/drei";
import { degToRad, radToDeg } from "three/src/math/MathUtils";

interface AnimationControlProps {
  local: boolean;
  global: boolean;
}

interface AnimationStateProps {
  range: [number, number];
  isLoop?: boolean;
}

interface AnimationState {
  idle: AnimationStateProps;
  side: AnimationStateProps;
  jump: AnimationStateProps;
  greetings: AnimationStateProps;
  no: AnimationStateProps;
  yes: AnimationStateProps;
  bully: AnimationStateProps;
}

interface ModelData {
  rotationY: number;
  rotationX: number;
  activeBounds: boolean;
  scale: number;
}

interface DuckProps extends ComponentPropsWithoutRef<any> {
  isListening?: boolean;
  initialState?: AnimationStateKeys;
  interactuableAnimations?: boolean;
  cursorAnimations?: boolean;
  cursorAngleLimit?: number;
  cursorSmoothFactor?: number;
  modelData?: ModelData;
}

/*
ABOUT EYE ANIMATIONS:
For the use of model eye animations, besides blinking which is always implemented:
- Set cursorAnimations to true.
- Pass accurate ModelData. If the model is animated, you need to pass dynamic model data (update the model data in the onUpdate     callback of GSAP if you are using it).
- If you are animating the model's position and rotation with GSAP, you should use the activeBounds prop when necessary. It is useful to avoid the model's eyes from turning white. However, its implementation can be a bit tricky."

*/

const Duck = ({
  isListening = true,
  initialState = AnimationStateKeys.IDLE,
  interactuableAnimations = true,
  cursorAnimations = false,
  cursorAngleLimit = 0,
  cursorSmoothFactor = 0.35,
  modelData = {
    rotationY: 0,
    rotationX: 0,
    activeBounds: true,
    scale: 2,
  },
  ...props
}: DuckProps) => {
  const states: AnimationState = {
    idle: {
      range: [0, 50],
      isLoop: true,
    },
    side: {
      range: [50, 100],
    },
    jump: {
      range: [150, 201],
    },
    greetings: {
      range: [250, 335],
    },
    no: {
      range: [325, 425],
    },
    yes: {
      range: [475, 555],
    },
    bully: {
      range: [560, 644],
    },
  };

  const modelRef = useRef(null);

  const modelInformation = modelData ?? undefined;

  const matcap = useTexture("/matcap_base_3.png");
  matcap.encoding = sRGBEncoding;

  const matcapPupilMat = useMemo(
    () =>
      new MeshMatcapMaterial({
        matcap: matcap,
        color: "#000000",
      }),
    [matcap]
  );
  const matcapEyesMat = useMemo(
    () =>
      new MeshMatcapMaterial({
        matcap: matcap,
        color: "#FFFFFF",
      }),
    [matcap]
  );
  const matcapBodyMat = useMemo(
    () =>
      new MeshMatcapMaterial({
        matcap: matcap,
        color: "#fdc322",
      }),
    [matcap]
  );
  const matcapMouthMat = useMemo(
    () =>
      new MeshMatcapMaterial({
        matcap: matcap,
        color: "#f48206",
      }),
    [matcap]
  );
  const matcapMouthInsideMat = useMemo(
    () =>
      new MeshMatcapMaterial({
        matcap: matcap,
        color: "#552806",
      }),
    [matcap]
  );

  const mixer = useRef<AnimationMixer>();

  const [model, setModel] = useState(null);
  const [animations, setAnimations] = useState(null);
  const [localState, setLocalState] = useState<AnimationStateProps>(
    states[initialState]
  );
  const [onAnimation, setOnAnimation] = useState<AnimationControlProps>({
    local: false,
    global: false,
  });

  const { globalState } = useDuck();

  const { duck } = useModelStore();

  const previousRotation = useRef({ x: 0, y: 0 });
  const eyes = useRef([]);
  const eyesMorphs = useRef([]);
  const head = useRef([]);

  //Initialization handler
  useEffect(() => {
    if (duck == null) return;

    const scene = duck[0];
    const animations = duck[1];

    const sceneClone = clone(scene);

    setAnimations(animations);
    setModel(sceneClone);

    sceneClone.traverse((child: any) => {
      if (child.isMesh) {
        switch (child.name) {
          case "Mesh009_3":
            child.material = matcapMouthInsideMat;
            break;
          case "Mesh006_2":
          case "Mesh008_2":
            child.material = matcapPupilMat;
            break;
          case "Mesh009_1":
          case "Mesh005":
            child.material = matcapBodyMat;
            break;
          case "Mesh009_2":
            child.material = matcapMouthMat;
            break;
          default:
            child.material = matcapEyesMat;
        }
      }
    });
  }, [
    duck,
    matcapBodyMat,
    matcapEyesMat,
    matcapMouthInsideMat,
    matcapMouthMat,
    matcapPupilMat,
  ]);

  useEffect(() => {
    if (model && cursorAnimations) {
      model.traverse((child) => {
        if (child.name === "Head_Base") head.current.push(child);
        if (["R_Eye_Joint", "L_Eye_Joint"].includes(child.name))
          eyes.current.push(child);
      });
    }
  }, [model, cursorAnimations]);

  useEffect(() => {
    if (model) {
      model.traverse((child) => {
        if (child.morphTargetDictionary) eyesMorphs.current.push(child);
      });
    }
  }, [model]);

  const eyeMorphTargets = {
    LEFT_EYE_CLOSE_DOWN: "L_UppereyeLid_DownShape",
    LEFT_EYE_CLOSE_UP: "L_LowereyeLid_UpShape",
    RIGHT_EYE_CLOSE_DOWN: "R_UppereyeLid_DownShape",
    RIGHT_EYE_CLOSE_UP: "R_LowereyeLid_UpShape",
  };

  const blinkDuration = 175;
  const blinkIntervalDuration = 5500;

  // Function to animate eye blinking
  const blinkAnimation = useCallback((direction) => {
    const startTime = performance.now();

    function animate() {
      const progress = Math.min(
        (performance.now() - startTime) / blinkDuration,
        1
      );

      eyesMorphs.current.forEach((child) => {
        Object.values(eyeMorphTargets).forEach((target) => {
          if (child.morphTargetDictionary[target] !== undefined) {
            child.morphTargetInfluences[child.morphTargetDictionary[target]] =
              direction === "open" ? 1 - progress : progress;
          }
        });
      });

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    animate();
  }, []);

  // Function to blink eyes
  const blink = useCallback(() => {
    blinkAnimation("close");
    setTimeout(() => {
      blinkAnimation("open");
    }, blinkDuration);
  }, [blinkAnimation]);

  // Effect to start blinking
  useEffect(() => {
    if (model && eyesMorphs.current.length > 0) {
      const intervalID = setInterval(blink, blinkIntervalDuration);
      return () => clearInterval(intervalID);
    }
  }, [model, eyesMorphs, blink]);

  //Local animation state handler
  useEffect(() => {
    if (!animations || !model) return;

    mixer.current = new AnimationMixer(model);
    animations.forEach((clip) => {
      const action = mixer.current.clipAction(clip);
      const startTime =
        (localState.range[0] / clip.tracks[0].times.length) * clip.duration;

      action.clampWhenFinished = true;
      action.time = startTime;
      if (clip.name === "Armature|Take 001|BaseLayer") action.play();
    });
  }, [animations, model, localState]);

  //Global animation state handler
  useEffect(() => {
    if (duck && isListening) playGlobalAnimation(states[globalState.state]);
  }, [globalState, isListening, duck]);

  const mouse = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (cursorAnimations) {
      const handler = (e) => {
        const x = (e.clientX / window.innerWidth) * 2 - 1;
        const y = -(e.clientY / window.innerHeight) * 2 + 1;
        mouse.current = { x, y };
      };
      window.addEventListener("mousemove", handler);
      return () => window.removeEventListener("mousemove", handler);
    }
  }, [cursorAnimations]);

  //Update animation state handler
  useFrame((_, delta) => {
    if (mixer.current == null) return;

    mixer.current.update(delta);

    animations.forEach((clip) => {
      const action = mixer.current.clipAction(clip);
      const endTime =
        (localState.range[1] / clip.tracks[0].times.length) * clip.duration;

      if (action.time >= endTime) {
        if (localState.isLoop) {
          action.time = localState.range[0];
        } else {
          setLocalState(states.idle);
        }
        setOnAnimation({ local: false, global: false });
      }
    });

    if (cursorAnimations && modelData) {
      const { rotationY, scale, activeBounds } = modelData;
      const modelInfoY = Math.abs(rotationY) / 2;
      const targetRotationX =
        (-Math.PI * mouse.current.y * 0.25) / (scale * 0.35);
      const targetRotationY = activeBounds
        ? (Math.PI * mouse.current.x * 0.25) / (scale * 0.25) - modelInfoY
        : (Math.PI * mouse.current.x * 0.25) / (scale * 0.25);

      cursorSmoothFactor = 0.35;
      const smoothRotationX = MathUtils.lerp(
        previousRotation.current.x,
        targetRotationX,
        cursorSmoothFactor
      );
      const smoothRotationY = MathUtils.lerp(
        previousRotation.current.y,
        targetRotationY,
        cursorSmoothFactor
      );

      const eyeRotationX =
        cursorAngleLimit == 0
          ? smoothRotationX
          : MathUtils.clamp(
              smoothRotationX,
              -degToRad(cursorAngleLimit),
              degToRad(cursorAngleLimit)
            );
      const eyeRotationY =
        cursorAngleLimit == 0
          ? smoothRotationY
          : MathUtils.clamp(
              smoothRotationY,
              -degToRad(cursorAngleLimit),
              degToRad(cursorAngleLimit)
            );

      if (eyes.current.length >= 2) {
        eyes.current.forEach((eye) => {
          eye.rotation.y = eyeRotationY;
          eye.rotation.x = eyeRotationX;
        });
      }

      if (head.current.length > 0) {
        head.current[0].rotation.y = smoothRotationY / 2.65;
        head.current[0].rotation.x = smoothRotationX / 2.65;
      }

      previousRotation.current = { x: smoothRotationX, y: smoothRotationY };
    }
  });

  const handleAnimation = useCallback(
    (control: AnimationControlProps, animation: AnimationStateProps) => {
      if (onAnimation.local || onAnimation.global) return;
      setOnAnimation(control);
      setLocalState(animation);
    },
    [onAnimation]
  );

  const playLocalAnimation = useCallback(
    (animation: AnimationStateProps) => {
      if (interactuableAnimations === false) return;
      handleAnimation({ local: true, global: onAnimation.global }, animation);
    },
    [handleAnimation, onAnimation.global]
  );

  const playGlobalAnimation = useCallback(
    (animation: AnimationStateProps) => {
      if (onAnimation.global) return;
      setOnAnimation({ local: false, global: true });
      setLocalState(animation);
    },
    [onAnimation.global]
  );

  useEffect(() => {
    return () => {
      if (model) {
        if (mixer.current) {
          mixer.current.stopAllAction();
          mixer.current.uncacheRoot(model);
          mixer.current = null;
        }
        model.traverse((child: any) => {
          if (child.isMesh) {
            child.geometry.dispose();
            if (child.material) {
              if (Array.isArray(child.material)) {
                child.material.forEach((material) => material.dispose());
              } else {
                child.material.dispose();
              }
            }
          }
        });
      }
    };
  }, []);

  return (
    model && (
      <primitive
        {...props}
        ref={modelRef}
        object={model}
        onPointerEnter={() => playLocalAnimation(states.greetings)}
        onPointerDown={() => playLocalAnimation(states.jump)}
      />
    )
  );
};

export default Duck;
